import React from 'react';
import './App.css';
import UploadForm from './components/UploadForm';
import logo from './assets/imgfront.webp';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Te lo transcribo - Herramienta de Transcripción de Audio y Video</title>
        <meta name="description" content="Convierte tus archivos de audio y video en texto con nuestra herramienta gratuita de transcripción. Sin límite y sin registro." />
        <meta name="keywords" content="transcripción de audio, transcripción de video, inteligencia artificial, herramienta gratuita de transcripción" />
      </Helmet>
      
      <header className="App-header">
        <div className="navbar">
          <h1 className="navbar-title">Te lo transcribo</h1>
          
          <nav className="navbar-buttons">
            <a href="#agradecer" className="navbar-button">Agradecer</a>
            <a href="/" className="navbar-button navbar-button-home">Inicio</a>
          </nav>
          
        </div>
      </header>
      <div className="container">
        <div className="text-column">
          <h2 className="main-text">Transcripciones sin límite de tus videos y audios</h2>
          <p className="sub-text">Sube tu archivo de audio o video y recibe en tu correo la transcripción, <b>Gratis</b> y <b>SIN registrar una cuenta</b></p>
        </div>
        <div className="image-column">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="form-column">
          <div className="upload-form-container">
            <UploadForm />
          </div>
        </div>
      </div>
      <div className="description">
        <p>Convierte tus archivos de <strong><u>audio o video en texto</u></strong> con ayuda de <strong><u>Inteligencia Artificial</u></strong> y completamente gratis.</p>
      </div>
    </div>
  );
}

export default App;
