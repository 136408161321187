import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './UploadForm.css';
import successImage from '../assets/recibido.webp';
import errorImage from '../assets/error.webp';
import dotenv from 'dotenv';

dotenv.config();

Modal.setAppElement('#root');

function UploadForm() {
  const defaultEmail = process.env.REACT_APP_DEFAULT_EMAIL || '';
  const apiUrl = process.env.REACT_APP_API_URL || 'https://back.telotranscribo.com/upload';

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [email, setEmail] = useState(defaultEmail);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size <= 507 * 1024 * 1024) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      alert('El archivo debe ser menor a 500 MB');
      setFile(null);
      setFileName('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Por favor, suba un archivo');
      return;
    }
    if (!validateEmail(email)) {
      alert('Por favor, ingrese un correo electrónico válido');
      return;
    }

    setModalIsOpen(true);
    setUploadComplete(false);
    setUploadSuccess(false);
    setMessage('');
    setProgress(0);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', email);

    try {
      await axios.post(apiUrl, formData, {
        headers: {
          'x-api-key': 'sagoaxny',
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (event) => {
          setProgress(Math.round((event.loaded * 100) / event.total));
          if (event.loaded === event.total) {
            setUploadComplete(true);
            setUploadSuccess(true);
            setMessage('En unos minutos recibirás la transcripción en tu correo electrónico, el tiempo puede variar dependiendo del tamaño del contenido, ¡Gracias por usar telotranscribo!');
          }
        }
      });
    } catch (error) {
      console.error('Error al subir el archivo:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
      }
      setUploadComplete(true);
      setUploadSuccess(false);
      setMessage('Error al subir el archivo');
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFile(null);
    setFileName('');
    setEmail(defaultEmail);
    setProgress(0);
    setMessage('');
    setUploadComplete(false);
    setUploadSuccess(false);
  };

  return (
    <div className="upload-form-container">
      <form className="upload-form" onSubmit={handleSubmit}>
      <h2 className="convert-text">Conviértelo a texto</h2>
        <div>
          <input type="file" accept="audio/*,video/*" id="file" onChange={handleFileChange} />
          <label htmlFor="file" className="file-label">Selecciona tu audio o video</label>
          {fileName && <p>Archivo seleccionado: {fileName}</p>}
        </div>
        <div>
          <input type="email" placeholder="Correo Electrónico" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <button type="submit">Convertir </button>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        shouldCloseOnOverlayClick={false}
        contentLabel="Progreso de Subida"
        className="Modal"
        overlayClassName="Overlay"
      >
        {!uploadComplete ? (
          <>
            <h2 className="modal-title">Subiendo Archivo...</h2>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
          </>
        ) : (
          <div className="message">
            <img src={uploadSuccess ? successImage : errorImage} alt={uploadSuccess ? "Success" : "Error"} className="success-image" />
            <p>{message}</p>
            <button onClick={closeModal}>Cerrar</button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default UploadForm;
